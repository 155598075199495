import Api from '@/services/Index';

const index = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/undergraduate/transfer/preference/index', formData);
}

const store = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/undergraduate/transfer/preference', formData);
}

const save = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/undergraduate/transfer/preference/save', formData);
}

const del = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/undergraduate/transfer/preference', {
        data: formData,
    });
}

export default {
    index,
    store,
    save,
    del
}
