<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="2">
                    <ValidationProvider name="rank" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('order_of_preference')">
                            <b-form-select v-model="form.rank"
                                           :class="errors[0] ? 'is-invalid': null"
                                           :options="rankOptions"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="faculty_code" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="form.faculty_code"
                                               :public="true"
                                               level="OL,L"
                                               :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="program_code" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="form.program_code"
                                               :public="true"
                                               :faculty_code="form.faculty_code"
                                               :is_faculty_code_required="true"
                                               :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="point_type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('point_type')">
                            <parameter-selectbox v-model="form.point_type"
                                                 code="point_types"
                                                 :public="true"
                                                 :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="class" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('class')">
                            <classes-selectbox v-model="form.class"
                                               :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center">
                <b-button variant="primary" class="mt-0 mt-lg-5" @click="storePreference">
                    {{ $t('add') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Component
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox'
    import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox'
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox'
    import ClassesSelectbox from '@/components/interactive-fields/ClassesSelectbox'

    // Services
    import UndergraduateTransferPreferenceService from '@/services/UndergraduateTransferPreferenceService'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            FacultySelectbox,
            ProgramSelectbox,
            ParameterSelectbox,
            ClassesSelectbox,

            ValidationProvider,
            ValidationObserver
        },
        props: {
            undergraduate_transfer_id: {
                type: Number
            },
            pin: {
                type: String
            },
            preferences: {
                type: Array,
                default: []
            }

        },
        computed: {
            rankOptions() {
                let options = [1, 2];
                let selected = this.preferences.length > 0 ? this.preferences.map(item => item.rank) : [];

                return options.filter(x => !selected.includes(x));
            }
        },
        data() {
            return {
                formLoading: false,
                form: {
                    rank: null,
                    faculty_code: null,
                    program_code: null,
                    point_type: null,
                    class: null
                }
            }
        },
        methods: {
            async storePreference() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    let formData = {
                        'undergraduate_transfer_id': this.undergraduate_transfer_id,
                        'pin': this.pin,
                        ...this.form
                    }

                    UndergraduateTransferPreferenceService.store(formData)
                                                          .then(response => {
                                                              this.$emit('savePreferences')
                                                          })
                                                          .then(() => {
                                                              this.$refs.formModalValidate.reset();
                                                          })
                                                          .catch(e => {
                                                              this.showErrors(e, this.$refs.formModalValidate);
                                                          })
                                                          .finally(() => {
                                                              this.formLoading = false
                                                          })
                }
            }
        }
    }
</script>
